import api from '../api/api'
import { getSetters, parseError } from './helpers/shared'
import { captureException } from '@sentry/vue'
import { track } from '../../plugins/analytics'
import store from '../index'
import logger from '@shared/logger'

export const state = {
  creditBalance: null,
  stripeToken: null,

  tax: null,
  credit: null,
  chargeAmount: null,
  price: null,
  discountAmount: null,
  activatedGiftCardAmount: null,

  queryPromoCode: null,

  casePrice: null,
  caseTotal: null,
  caseTax: null,

  cancelReasons: [],

  enteredCodes: [],
  validGiftCards: [],
  invalidGiftCards: [],

  membershipPlans: null,
  membershipPlanSelectedId: null,
  membershipPlanQuotes: {},

  referralCampaign: null,
  referralCode: null,
  referralSource: null,
  referringClient: null,

  leadSource: null,
  leadSourceDetail: null,
  leadSourceDetailRequired: false,

  errors: null
}

export const leadSourceOptions = [
  {
    text: 'Armoire Event',
    value: 'events',
    detailPrompt: () => 'Event Name'
  },
  {
    text: 'Other Website',
    value: 'website',
    detailPrompt: () => 'Name of Website'
  },
  {
    text: 'Email/SMS',
    value: 'email',
    detailPrompt: () => null,
    detailOptions: [
      { text: 'Email', value: 'email' },
      { text: 'SMS', value: 'sms' }
    ]
  },
  {
    text: 'Podcast / Radio',
    value: 'podcast-radio',
    detailPrompt: () => 'Podcast or Station Name'
  },
  {
    text: 'Someone told me',
    value: 'referral',
    detailPrompt: () => 'Referrer\'s Name'
  },
  {
    text: 'Searched Online',
    value: 'search',
    detailPrompt: () => null,
    detailOptions: [
      { text: 'Google', value: 'google' },
      { text: 'Bing', value: 'bing' },
      { text: 'Other', value: 'other' }
    ]
  },
  {
    text: 'Armoire Go Store',
    value: 'retail',
    detailPrompt: () => null
  },
  {
    text: 'Social Media',
    value: 'social',
    detailPrompt: () => null,
    detailOptions: [
      { text: 'Facebook', value: 'facebook' },
      { text: 'Instagram', value: 'instagram' },
      { text: 'Pinterest', value: 'pinterest' },
      { text: 'TikTok', value: 'tiktok' },
      { text: 'Twitter', value: 'twitter' },
      { text: 'YouTube', value: 'youtube' },
      { text: 'LinkedIn', value: 'linkedin' },
      { text: 'Other', value: 'other' }
    ]
  },
  {
    text: 'Other/Unsure',
    value: 'other',
    detailPrompt: () => 'Please specify'
  }
]

export const rejoinOptions = [
  {
    text: 'I received an email deal to rejoin',
    value: 'rejoin-email',
    detailPrompt: () => null
  },
  {
    text: 'My budget changed',
    value: 'rejoin-budget',
    detailPrompt: () => null
  },
  {
    text: 'My clothing needs changed',
    value: 'rejoin-needs-changed',
    detailPrompt: () => null,
    detailOptions: [
      { text: 'I\'m going back to work', value: 'back-to-work' },
      { text: 'I have a new job', value: 'new-job' },
      { text: 'My sizes have changed', value: 'sizes-changed' },
      { text: 'I\'m pregnant and need maternity styles', value: 'need-maternity' },
      { text: 'I have more social engagements', value: 'social-engagements' }
    ]
  },
  {
    text: 'I\'m renting for a special event',
    value: 'rejoin-special-event',
    detailPrompt: () => null
  },
  {
    text: 'I\'m renting for a vacation',
    value: 'rejoin-vacation',
    detailPrompt: () => null
  },
  {
    text: 'I rent clothes this time of year',
    value: 'rejoin-season',
    detailPrompt: () => null
  },
  {
    text: 'Armoire has new inventory I want to try',
    value: 'rejoin-inventory',
    detailPrompt: () => null
  },
  {
    text: 'Other',
    value: 'rejoin-other',
    detailPrompt: () => 'Reason'
  }
]

export const getters = {
  prepayPlans: state => prepayPlanGroupName => {
    let plans = []
    if (state.membershipPlans) {
      plans = state.membershipPlans.filter(plan => plan.name.includes('Monthly') || plan.name.includes('Quarterly') || plan.name.includes('Semiannual') || plan.name.includes('Annual'))
    }
    switch (prepayPlanGroupName) {
      case 'unlimited':
        plans = plans.filter(plan => (!plan.shipmentsPerInterval))
        break
      case '7-item':
        plans = plans.filter(plan => (plan.shipmentsPerInterval && plan.nItems === 7))
        break
      case '4-item':
        plans = plans.filter(plan => (plan.shipmentsPerInterval && plan.nItems === 4))
        break
    }
    return plans
  },
  refereeBonusItems: (state, getters) => {
    if (getters.bestReferralPlan) {
      return getters.bestReferralPlan.refereeBonusItems
    }
    return state.referralCampaign?.refereeBonusItems
  },
  refereeDiscount: (state, getters) => {
    if (getters.bestReferralPlan) {
      return getters.bestReferralPlan.effectivePercentOff
    }
    return state.referralCampaign?.refereePlanPercentOff
  },
  bestReferralPlan: (state) => {
    if (state.referralCampaign) {
      const plans = state.referralCampaign.planDiscounts.filter(x => x.isFeaturedDeal)
      if (plans.length > 0) { return plans[0] }
      if (state.referralCampaign.planDiscounts.length > 0) {
        return state.referralCampaign.planDiscounts[0]
      }
    }
    return null
  },
  showReferralBanner: (state) => {
    return state.referralCode && state.referralCampaign
  },
  showPromoBanner: (state, getters) => {
    return getters.defaultPromoForSelectedPlan?.promoCode && !getters.appliedPromoForSelectedPlan?.promoCode
  },
  defaultPromoForSelectedPlan: (state, getters) => {
    return getters.membershipPlanSelected?.defaultPromo ?? null
  },
  appliedPromoForSelectedPlan: (state, getters) => {
    return getters.membershipPlanSelected?.appliedPromo ?? null
  },
  membershipPlanFromId: state => planId => {
    return state.membershipPlans
      ? state.membershipPlans.find(x => x.id === planId)
      : null
  },
  membershipPlanSelected: (state, getters) => {
    return state.membershipPlanSelectedId
      ? getters.membershipPlanFromId(state.membershipPlanSelectedId)
      : null
  },
  trialPriceFromPlan: () => plan => {
    if (!plan) return null

    const { contextualPricing, defaultPromo, basePrice } = plan

    if (contextualPricing) {
      return contextualPricing.price / 100.0
    } else if (defaultPromo) {
      return (basePrice - (defaultPromo.dollarAmount)) / 100.0
    } else {
      return basePrice / 100.0
    }
  },
  selectedPlanReferralPrice: (state, getters) => {
    return state.membershipPlanSelected
      ? getters.referralPriceFromPlan(state.membershipPlanSelected)
      : null
  },
  selectedPlanReferralBonusItems: (state, getters) => {
    return state.membershipPlanSelectedId
      ? getters.bonusItemsFromPlanId(state.membershipPlanSelectedId)
      : null
  },
  bonusItemsFromPlanId: (state, getters) => planId => {
    if (!getters.membershipPlanFromId(planId)) return 0
    if (!state.referralCampaign) return 0

    const planDiscounts = state.referralCampaign.planDiscounts.filter(x => x.plan.id === planId)
    if (planDiscounts.length > 0) {
      return planDiscounts[0].refereeBonusItems
    }
    // fallback
    return state.referralCampaign.refereeBonusItems
  },
  referralPriceFromPlan: (state) => plan => {
    if (!plan) return null
    if (!state.referralCampaign) return plan.basePrice / 100.0

    const planDiscounts = state.referralCampaign.planDiscounts.filter(x => x.plan.id === plan.id)
    if (planDiscounts.length > 0) {
      return planDiscounts[0].effectivePrice / 100
    }
    const discount = state.referralCampaign.refereePlanPercentOff / 100.0
    const basePrice = plan.basePrice / 100.0
    return basePrice - (basePrice * discount)
  },
  discountPriceFromPlan: (state, getters) => plan => {
    return state.referralCode
      ? Number(getters.referralPriceFromPlan(plan).toFixed(2))
      : getters.trialPriceFromPlan(plan)
  },
  referralDiscountText: (state) => plan => {
    if (!state.referralCampaign) { return '' }
    const planDiscounts = state.referralCampaign.planDiscounts.filter(x => x.plan.id === plan.id)
    if (planDiscounts.length > 0) {
      if (planDiscounts[0].planAmountOff) {
        return `$${planDiscounts[0].planAmountOff / 100}`
      }
      return `${planDiscounts[0].planPercentOff}%`
    }
    return `${state.referralCampaign.refereePlanPercentOff}%`
  }
}

export const actions = {
  async parseQueryParams ({ commit, dispatch }, { query }) {
    if (query.r) {
      const referralClient = await dispatch('subscribe/validateReferralCode', query.r, { root: true })
      if (referralClient) {
        commit('SET_REFERRING_CLIENT', referralClient)
        commit('SET_REFERRAL_CODE', query.r)
        if (query.s) {
          commit('SET_REFERRAL_SOURCE', query.s)
        }
      }
    } else if (query.promo) {
      dispatch('subscribe/setQueryPromoCode', query.promo, { root: true })
    }
    if (query.plan) {
      commit('SET_MEMBERSHIP_PLAN_SELECTED_ID', query.plan)
    }
  },
  async selectMembershipPlan ({ commit, getters }, { planId, trackEvent = false }) {
    const previousPlanName = getters.membershipPlanSelected ? getters.membershipPlanSelected.name : ''
    commit('SET_MEMBERSHIP_PLAN_SELECTED_ID', planId)
    commit('SET_ENTERED_CODES', [])
    const { name } = getters.membershipPlanFromId(planId)
    if (trackEvent && (previousPlanName !== name)) {
      track('Membership - Plan Changed', {
        to: name,
        from: previousPlanName
      })
    }
  },
  setQueryPromoCode ({ commit }, promoCode) {
    commit('SET_QUERY_PROMO_CODE', promoCode?.toLowerCase())
  },
  async callGetSubscriptionQuote ({ commit, state, getters }, data) {
    try {
      const promoCode = data?.referralCode || state.referralCode ? null : data?.promoCode || state.queryPromoCode || getters.membershipPlanSelected?.appliedPromo?.promoCode
      const gcRegex = /^[\w\d]{4}-[\w\d]{4}-[\w\d]{4}-[\w\d]{4}$/
      let filtered = [...state.enteredCodes]
      if (!gcRegex.test(promoCode)) {
        // looks like we've got a new promo code
        // filter out any previously entered codes that don't look like a
        // gift card, since we can only have one promo code applied at a time.
        filtered = state.enteredCodes.filter((code) => {
          return gcRegex.test(code)
        })
      }
      const codes = [...filtered, promoCode].filter(Boolean)
      const req = {
        promo_codes: codes,
        plan: getters.membershipPlanSelected?.name,
        referrer_code: data?.referralCode || state.referralCode
      }
      if (!req.plan) {
        logger.warn('No plan selected, skipping subscription quote', req)
        return
      }
      logger.info('Getting subscription quote', req)
      const subQuote = await api.apiSubscribe.getSubscriptionQuote(req)
      const {
        plan, tax, chargeAmount, price, discount, credit, remainingCredit,
        validGiftCards, invalidGiftCards, activatedGiftCardAmount
      } = subQuote.data

      if (state.membershipPlans.filter(p => p.id === plan.id).length > 0) {
        commit('SET_MEMBERSHIP_PLANS', state.membershipPlans.map(p => p.id === plan.id ? plan : p))
      } else {
        commit('SET_MEMBERSHIP_PLANS', [...state.membershipPlans, plan])
      }
      commit('SET_MEMBERSHIP_PLAN_SELECTED_ID', plan.id)
      commit('SET_TAX', tax)
      commit('SET_CREDIT', credit)
      commit('SET_CHARGE_AMOUNT', chargeAmount)
      commit('SET_CREDIT_BALANCE', remainingCredit)
      commit('SET_PRICE', price)
      commit('SET_DISCOUNT_AMOUNT', discount)
      commit('SET_ACTIVATED_GIFT_CARD_AMOUNT', activatedGiftCardAmount)
      commit('SET_VALID_GIFT_CARDS', validGiftCards)
      commit('SET_INVALID_GIFT_CARDS', invalidGiftCards)
      // strip out bad codes so we don't keep re-submitting them and
      // consequently keep showing the same error messages
      const validCodes = codes.filter((code) => !invalidGiftCards.includes(code))
      commit('SET_ENTERED_CODES', validCodes)
    } catch (error) {
      logger.error(error)
      throw parseError(error)
    }
  },
  async getCasePrice ({ commit, dispatch, rootState }) {
    try {
      const data = {}
      data.selected_item_types = rootState.closet.selectedItemTypes.map(itemType => itemType.id)
      const res = await dispatch('client/getCasePrice', data, { root: true })
      commit('SET_CASE_TAX', res.data.tax)
      commit('SET_CASE_PRICE', res.data.subtotal)
      commit('SET_CASE_TOTAL', res.data.total)
    } catch (err) {
      throw parseError(err)
    }
  },
  // zeros out any promo codes previously applied
  async clearAllPromos ({ commit }) {
    try {
      commit('SET_QUERY_PROMO_CODE', null)
    } catch (err) {
      throw parseError(err)
    }
  },
  async fetchPlans ({ commit }) {
    try {
      const res = await api.apiSubscribe.getPlans(state.queryPromoCode)
      if (res.data.length) commit('SET_MEMBERSHIP_PLANS', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getCancelReasons ({ commit }) {
    try {
      const res = await api.apiSubscribe.getCancelReasons()
      let reasons = res.data
      // randomize the reasons ordering
      reasons = reasons.map(
        option => ({ ...option, order: Math.random() })
      ).sort((a, b) => {
        return a.order - b.order
      })
      reasons.forEach(r => {
        if ('secondaryReasons' in r) {
          r.secondaryReasons = r.secondaryReasons.map(
            sr => ({ ...sr, order: Math.random() })
          ).sort((a, b) => {
            return a.order - b.order
          })
        }
      })
      commit('SET_CANCEL_REASONS', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async subscribeClient ({ commit, dispatch }, data) {
    try {
      const res = await api.apiSubscribe.subscribeClient(data)
      commit('client/UPDATE_CLIENT', res.data.client, { root: true })
      if (res.data.token) {
        commit('client/UPDATE_CLIENT', {
          defaultPayment: {
            brand: data.token.card.brand,
            last4: data.token.card.last4,
            expMonth: data.token.card.exp_month,
            expYear: data.token.card.exp_year
          }
        }, { root: true })
      }
      dispatch('client/getClientFollowup', {}, { root: true })
      store.commit('styleQuizNav/SET_IS_PREPAY_FLOW', false)
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async changePlan ({ commit }, data) {
    try {
      const res = await api.apiSubscribe.changePlan(data)
      commit('client/UPDATE_CLIENT', res.data.client, { root: true })
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async getReferralCampaign ({ commit }) {
    try {
      const res = await api.apiSubscribe.getReferralCampaign()
      commit('SET_REFERRAL_CAMPAIGN', res.data[0])
    } catch (err) {
      throw parseError(err)
    }
  },
  async getQuote ({ commit, state }, data) {
    try {
      const planId = data.planId
      const res = await api.apiSubscribe.getQuote(data)
      commit('SET_MEMBERSHIP_PLAN_QUOTES', { ...state.membershipPlanQuotes, [planId]: res.data })
    } catch (err) {
      throw parseError(err)
    }
  },
  async validateReferralCode (context, code) {
    try {
      const res = await api.apiSubscribe.validateReferralCode(code)
      return res.data
    } catch (err) {
      captureException(err)
      // Invalid referral codes result in 404
    }
  },
  async applyGeneralPromoCode ({ commit, dispatch }, enteredCode) {
    let errors = []
    try {
      const referralClient = await dispatch('subscribe/validateReferralCode', enteredCode, { root: true })
      if (referralClient) {
        const err = await dispatch('subscribe/callGetSubscriptionQuote', { referralCode: enteredCode }, { root: true })
        if (!err) {
          commit('SET_REFERRING_CLIENT', referralClient)
          commit('SET_REFERRAL_CODE', enteredCode)
        } else {
          errors = err
        }
      } else {
        commit('SET_REFERRAL_CODE', null)
        commit('SET_REFERRING_CLIENT', null)
        // Try the code as a promo code
        const err = await dispatch('subscribe/callGetSubscriptionQuote', { promoCode: enteredCode }, { root: true })
        if (err) {
          await this.applyPlanDefaultPromo()
          errors = err
        }
      }
    } catch (err) {
      errors = err
    }
    return errors
  },
  async applyPlanDefaultPromo ({ dispatch }) {
    let errors = []
    try {
      errors = await dispatch('subscribe/callGetSubscriptionQuote', { promoCode: this.membershipPlanSelected.defaultPromo.promoCode })
      if (errors) {
        return errors
      }
    } catch (err) {
      captureException(err)
      throw parseError(err)
    }
  }
}

export const mutations = {
  ...getSetters(state)
}
